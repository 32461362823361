<script lang="ts" setup>
import infoSvg from '@/assets/info.svg'
import { attrController } from '~/apis/back/services'

const props = defineProps({
  isClickTrigger: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const commonStore = useCommonStore()
const useInfoStore = useUserStore()
const isMobile = useMobileByScreen()

defineExpose({
  onConfirm,
})

const { data: result } = await attrController.attrListExperienceSearchFilterConfigPost({})
const { onFilterConfirm, filters } = useExperienceList()

const priceRange = computed(() => {
  return (result.value?.data?.priceRanges || []).find(item => item.currency === useInfoStore.userInfo.currency) || {}
})

const filterValue = reactive({
  freeCancellation: filters.value.freeCancellation,
  instantConfirmation: filters.value.instantConfirmation,
})

const sliderValue = ref<number[]>([
  priceRange?.value.min || 0, // min
  priceRange?.value.max || 100, // max
])

function onConfirm() {
  onFilterConfirm({
    freeCancellation: filterValue.freeCancellation ? 1 : undefined,
    instantConfirmation: filterValue.instantConfirmation ? 1 : undefined,
    priceMin: sliderValue.value[0],
    priceMax: sliderValue.value[1],
  })
}

function changeFilterHandle() {
  if (props.isClickTrigger) {
    onConfirm()
  }
}

watch(() => useInfoStore.userInfo.currency, () => {
  sliderValue.value = [priceRange?.value?.min || 0, priceRange?.value?.max || 100]
})

function openPolicy() {
  showDialog({
    showConfirmButton: false,
    closeOnClickOverlay: true,
    messageAlign: 'left',
    title: t('key.experience.cancellation.policy'),
    message: t('key.filter.cancellation.tooltip'),
  })
}
</script>

<template>
  <div>
    <div class="min-w-60 pt-2">
      <div p="x-4 y-3">
        <div class="pb-1 text-16px text-[#121212] font-500">
          {{ t('key.attractions.list.price') }}
        </div>
        <div text="#888888">
          <span> {{ commonStore.getCurrencySymbol }}{{ sliderValue[0] }} </span> - <span>{{ commonStore.getCurrencySymbol }}{{ sliderValue[1] }}</span> +
        </div>
        <div p="x-2">
          <ElSlider v-model="sliderValue" range :min="priceRange.min || 0" :max="priceRange.max || 100" :show-tooltip="false" @change="changeFilterHandle" />
        </div>
      </div>
    </div>
    <div p="x-4 b-1" font="600" text="base #121212">
      {{ t('key.filter.booking.options') }}
    </div>
    <div>
      <BaseAZCheckBox
        v-model="filterValue.freeCancellation"
        name="Free cancellation"
        icon-size="1.25rem"
        class="box-border w-full flex items-center px-4 py-1.5"
        @change="changeFilterHandle"
      >
        <span class="text-sm text-[#121212]" font="500">{{ t('key.experience.filter.free.cancellation') }}</span>
        <img v-if="isMobile" w="4" h="4" :src="infoSvg" m="l-1" @click.stop="openPolicy" />
        <el-tooltip
          v-else
          :content="t('key.filter.cancellation.tooltip')"
          placement="top"
          effect="light"
        >
          <img w="4" h="4" :src="infoSvg" m="l-1" />
        </el-tooltip>
      </BaseAZCheckBox>
      <BaseAZCheckBox
        v-model="filterValue.instantConfirmation"
        icon-size="1.25rem"
        name="Instant confirmation"
        class="box-border w-full flex items-center px-4 py-1.5"
        @change="changeFilterHandle"
      >
        <span class="text-sm text-[#121212]" font="500">{{ t('key.experience.filter.instant.confirmation') }}</span>
      </BaseAZCheckBox>
    </div>
  </div>
</template>
